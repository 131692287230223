<template>
    <div class="">
       <!-- <v-chip
                class="ma-2"
                color="pink"
                label
                text-color="white"
                v-if="snackbar.report"
                @click="backToMenu"
        >
            <v-icon left>mdi-label</v-icon>
            Reports
        </v-chip>-->
        <div class="reportTable" v-if="snackbar.immigrationReport">
            <component :is="componentName"></component>
        </div>
        <div class=" pas-flex-container pt-5" v-else>
            <v-chip
                    class="ma-2 pas-flex-item"
                    label
                    v-for="(item,index) in titles" :key="index"
                    @click="loadReports(item)"
            >
                {{item.name}}
            </v-chip>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Tourist-ArrivalReportMenu",
        props: [
            'branchID',
            'divisionName'
        ],
        data() {
            return {
                titles: [
                    {
                        name: this.$t('tourist-arrival_report'),
                        component: "ImmigrationArrivalReport"
                    },
                    {
                        name: this.$t('national_international_report'),
                        component: "NationalityReportTable"
                    },
                    {
                        name: this.$t('international_passenger_movement_report'),
                        component: "InternationalPassengerMovementReports"
                    },
                ],
                componentName: '',
                snackbar: {
                    report: false,
                    immigrationReport: false,
                }
            }
        },
        methods: {
            loadReports({component}) {
                this.componentName = component
                this.snackbar.immigrationReport = true
            },
            backToMenu() {
                this.componentName = ''
                this.snackbar.report = false
            },
            backToMenu2() {
                this.componentName = ''
                this.snackbar.immigrationReport = false
            },
            dialogueClose() {
                this.$emit('dialogueClose')
            }
        },
        mounted() {


        },
        computed: {},
        components: {
            ImmigrationArrivalReport: () => import(/* webpackChunkName: "ImmigrationArrivalReport" */ './ImmigrationArrivalReport'),
            NationalityReportTable: () => import(/* webpackChunkName: "NationalityReportTable" */ './NationalityReportTable'),
            InternationalPassengerMovementReports: () => import(/* webpackChunkName: "InternationalPassengerMovementReports" */ './InternationalPassengerMovementReports'),
        }
    }
</script>

<style scoped lang="scss">
    $blue: #236aac;
    $blue1: #061c6d;
    $red: #B72626;
    $grey: #eeeeee51;
    $grey1: #eeeeee99;
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $grey1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #4e434361;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #4e434361;
    }

    .pas-flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 5px
    }

    .pas-flex-item {
        box-shadow: 0 3px 5px 0 #eeeeee93;
        padding: 10px;
        flex-grow: 1;
        flex-basis: 200px;
        display: grid;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
</style>